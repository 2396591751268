import { navigate } from "gatsby";
import React, { useEffect } from "react";

const InfoPerIlPubblicoPage: React.FC = () => {
  useEffect(() => {
    navigate("/info-per-il-pubblico/reclami");
  }, []);
  return null;
};

export default InfoPerIlPubblicoPage;
